import React, { useEffect } from "react";
import NavbarHeader from "../pages/Navbar";
import FooterBfsg from "../pages/FooterBfsg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import SkinCare1 from "../Image/SkinCare/RMSBEAUTY-updated.png";
import SkinCare2 from "../Image/SkinCare/REVIVE.png";
import SkinCare3 from "../Image/SkinCare/SMASHBOX.png";
import BobbiBrown from "../Image/BobbiBrownSkincare.png";
import SkinCare4 from "../Image/SkinCare/BY TERRY-updated.png";
import SkinCare5 from "../Image/SkinCare/Kevyn-Aucoin.png";
import SkinCare6 from "../Image/SkinCare/Estee Lauder.png";
import SkinCare7 from "../Image/SkinCare/Byredo.png";
import SkinCare8 from "../Image/SkinCare/Immortelle.png";
import SkinCare9 from "../Image/SkinCare/VictoriyaBeckBeauty.png";
import SkinCare10 from "../Image/SkinCare/Supergoop.png";
import HeroBanner from "../Image/SkinCare/SkincareBanner.png";
function Skincare() {
  useEffect(() => {
    document.title =
      "Skincare by RMS Beauty, Revive, Bobby Brown, By Terry, Estree Launder | Beauty Fashion Sales Group ";
  }, []);

  return (
    <div>
      <NavbarHeader />
      <div>
        <section className="B3bPageTop">
          <div className="container">
            <div className="HeroBanner">
              <img src={HeroBanner} alt="" />
              <h3>SKINCARE</h3>
            </div>

            <div>
              <div className="row g-0">
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/BobbiBrown">
                    <div className="BrandProduct BB BR ">
                      <div>
                        <img src={BobbiBrown} alt="" />
                      </div>

                      <h2>Bobbi Brown</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/ByTerry">
                    <div className="BrandProduct BR  BB">
                      <div>
                        <img src={SkinCare4} alt="" />
                      </div>

                      <h2>BY TERRY</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/EsteeLaunder">
                    <div className="BrandProduct BR BB">
                      <div>
                        <img src={SkinCare6} alt="" />
                      </div>

                      <h2>ESTEE LAUDER</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/Occitane">
                    <div className="BrandProduct  BB">
                      <div>
                        <img src={SkinCare8} alt="" />
                      </div>

                      <h2>L'Occitane</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/ReVive">
                    <div className="BrandProduct BR ">
                      <div>
                        <img src={SkinCare2} alt="" />
                      </div>

                      <h2>REVIVE</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/RMSBeauty">
                    <div className="BrandProduct BR  ">
                      <div>
                        <img src={SkinCare1} alt="" />
                      </div>

                      <h2>RMS BEAUTY</h2>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 p-0">
                  <Link to="/brands/Supergoop">
                    <div className="BrandProduct BR">
                      <div>
                        <img src={SkinCare10} alt="Supergoop" />
                      </div>
                      <h2>Supergoop</h2>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                  <Link to="/brands/VictoriaBeckhamBeauty">
                    <div className="BrandProduct ">
                      <div>
                        <img src={SkinCare9} alt="Victoria Beckham Beauty" />
                      </div>

                      <h2>Victoria Beckham Beauty</h2>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterBfsg />
    </div>
  );
}

export default Skincare;
