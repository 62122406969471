import React from 'react'
import NavbarHeader from "../pages/Navbar"
import FooterBfsg from "../pages/FooterBfsg"
function FounderEdit() {
  return (

    <div>
 <NavbarHeader/>

    <div className='FounderEdit'>
        <h1>Coming Soon</h1></div>

        <FooterBfsg/>
    </div>
  )
}

export default FounderEdit